<template>
	<div>
		<H5header></H5header>
		
		<div class="articlelist" v-for="(item,index) in data" :key="index" @click="jump(item)">
			<div class="imgbox">
				<div class="title-img">{{item.name}}</div>
				<img :src="item.img_url" alt="" v-if="item.img_url">
				<img src="../../assets/image/placeholder.png" alt="" v-else>
			</div>
			<div class="date">{{item.create_time | format}}</div>
			<div class="xian"></div>
		</div>
		
	</div>
</template>

<script>
	import H5header from "./components/header3.vue"
	export default {
		name: '',
		components: {
			H5header,
		},
		data() {
			return {
				data: [],
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		created() {
			this.findList()
		},
		methods: {
			findList() {
				this.$api.bjSport(744,0).then(res => {
					this.data = res.data.data
				})
			},
			jump(item) {
				if(item.bind_theme == 0){
					window.open(item.link_address, '_blank');
				}else{
					this.$router.push({
						path: '/h5/' + item.link_address,
						query: {
							logo:item.img_url,
							title:item.name,
							refresh: new Date().getTime(),
						}
					})
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.articlelist {
	
		.title {
			height: 36PX;
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			margin: 17PX 22PX 0 22PX;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	
		.date {
			font-size: 11PX;
			font-family: Source Han Sans CN;
			font-weight: 400;
			margin-top: 5PX;
			margin-right: 23PX;
			text-align: right;
			margin-bottom: 12PX;
		}
	
	
		.imgbox {
			margin: 17PX 22PX 0 22PX;
			display: flex;
			justify-content: space-between;
	
			img {
				width: 114PX;
				height: 70PX;
				border-radius: 10PX;
				flex-shrink: 0;
			}
	
			.title-img {
				height: 73PX;
				font-size: 14PX;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				line-clamp: 4;
				-webkit-box-orient: vertical;
				margin-right: 10PX;
			}
		}
	}
	
	.xian {
		width: 100%;
		height: 4PX;
		background: #F4F8FB;
	}
</style>